<template>
  <div class="receipt__wrapper">
    <h1>{{ msg }}</h1>

    <div class="receipt__details">
      <div class="receipt__details__line">
        <h3>Stel til afhentning:</h3>
        <div v-if="codes.length">
          <b>Med kode:</b>
          <ul class="racks__list">
            <li v-for="item in codes" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
        <div v-if="extraA"><b>A-stel uden kode:</b> {{ extraA }}</div>
        <div v-if="extraL"><b>L-stel uden kode:</b> {{ extraL }}</div>
      </div>

      <div class="receipt__details__line">
        <h3>Afhentes:</h3>
        <div>
          <span v-if="location.companyName"
            >{{ location.companyName }} <br
          /></span>
          <span v-if="location.address">{{ location.address }} <br /></span>
          <span v-if="location.zip"
            >{{ location.zip }} {{ location.city }}</span
          >
        </div>
        <div>Kommentar:{{ location.comment }}</div>
      </div>
      <div class="receipt__details__line">
        <h3>Kontaktinfo:</h3>
        <div v-if="phone">
          Telefon:
          {{ phone }}
        </div>
        <div v-if="email">
          Email:
          {{ email }}
        </div>
        <div v-if="comment">
          Kommentar:
          {{ comment }}
        </div>
      </div>
    </div>
    <div class="buttons single">
      <button class="button" tabindex="2" @click="reset">
        Til forsiden
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReceiptDetails",
  props: {
    msg: String,
  },
  data: function() {
    return {
      orderDetails: null,
      codes: [],
      extraA: null,
      extraL: null,
      location: {
        address: "",
        zip: "",
        city: "",
        comment: null,
      },
      phone: "",
      email: "",
      comment: "",
    };
  },
  methods: {
    reset() {
      this.$router.push("/");
    },
  },
  mounted() {
    var vm = this;

    if (
      this.$store.state.codes === undefined &&
      this.$store.state.extraA === undefined &&
      this.$store.state.extraL === undefined
    ) {
      this.$router.push("/");
    } else {
      vm.orderDetails = this.$store.state;

      vm.codes = vm.orderDetails.codes ? vm.orderDetails.codes : [];
      vm.location = vm.orderDetails.location;
      vm.extraA = vm.orderDetails.extraA;
      vm.extraL = vm.orderDetails.extraL;
      vm.email = vm.orderDetails.email;
      vm.phone = vm.orderDetails.phone;
      vm.comment = vm.orderDetails.comment;
    }
  },
};
</script>
<style scoped lang="scss">
h1 {
  text-align: left;
}
h3 {
  margin: 0;
}
b {
  font-weight: 600;
}
.receipt {
  &__wrapper {
    width: 100%;
    margin: 2rem 0 6rem;
  }
  &__details {
    margin: 0;
    &__line {
      padding: 1rem 0 0.5rem;
      border-bottom: solid 1px #dbdbdb;
      &:first-child {
        h3 {
          margin-top: 1rem;
        }
      }

      > div {
        margin: 0.5rem 0;
        line-height: 1.5;
      }
    }
  }
}
.racks__list {
  margin: 0 0 1rem 0;
}
</style>
