<template>
  <div class="container receipt">
    <ReceiptDetails msg="Kvittering" />
  </div>
</template>

<script>
import ReceiptDetails from "@/components/ReceiptDetails.vue";

export default {
  name: "Receipt",
  components: {
    ReceiptDetails,
  },
};
</script>
